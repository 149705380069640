import React from "react";
import Layout from "../../Component/Layout/Layout";

function ErrorPage() {
  return (
    <Layout>
      <h1 style={{
        padding: 20,
      }}>Page Not Found</h1>
    </Layout>
  );
}

export default ErrorPage;
